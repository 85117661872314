<template>
  <layout :selectedNav="selectedNav">
    <div class="page-content__wrapper">
      <div class="page-content">
        <img src="@/assets/images/developing.png" />
        <span>页面开发中，请耐心等待...</span>
      </div>
    </div>
  </layout>
</template>
<script>
import Layout from "@/views/Layouts";
export default {
  components: {
    Layout
  },
  data() {
    return {
      selectedNav: null
    };
  },
  watch: {
    "$route.query": {
      deep: true,
      immediate: true,
      handler(val) {
        this.selectedNav = val.nav;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.page-content__wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "ZhanKuKuaiLeTi2016XiuDingBan-2", sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #333333;
    user-select: none;
    padding: 30px 0;

    img {
      margin-bottom: 10px;
    }
  }
}
</style>
